<template>
  <div class="text-center allbutton ">
  <button class="px-2 searchmap ma-1 bottom-black" @click="Addrequest">{{ $t("Real_request") }}</button>
  <button class="px-2 searchmap ma-1 bottom-black" @click="Addoffer">{{ $t("Real_offer") }}</button>

    <!--    -->

  </div>
 
</template>

<script>

export default {
  components: {
  },
  data() {
    return {};
  },
  methods: {
    gotomap(){
        this.$router.push({name:'Map'})
    },
    Addrequest(){
        this.$router.push({name:'Addrequest'})
    },
    Addoffer(){
        this.$router.push({name:'Addoffer'})
    }
  },
};
</script>
<style lang="scss" scoped>

.allbutton .searchmap{
  border: 1px solid #F6C712;;
   background-color: #F6C712;
   border-radius: 6px;
   font-weight: 500;
   height: 40px;
   width: 120px;
}
.allbutton .searchmap:hover{
  background-color: transparent;
  color: #F6C712;
}
</style>
