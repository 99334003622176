<template>
  <v-card class="mx-auto carddata h-100 card-ads" >
    <span class="span-type" v-if="type">{{ type }}</span>
    <span v-else></span>
    <v-img cover height="250" :src="image" @click="singleAd(idcard)"></v-img>
    <div>
      <v-card-item class="px-sm-3 px-1">
        <div
          class="d-flex flex-sm-row flex-column flex-no-wrap align-start justify-sm-space-between justify-start"
        >
          <h3 @click="singleAd(idcard)">
            {{ title.split('.')[0] }}
            {{ $t("SR") }}
          </h3>

        </div>
        <span class="me-1 subtitle font-weight-medium" v-if="subtitle">{{
            type +' '+ $t("For")+ad_type
        }}</span>
      </v-card-item>
      <div
        class="d-flex flex-column flex-no-wrap align-start justify-sm-space-between justify-start flex-no-wrap"
      >
        <div class="line-date-and-city" >
          <v-card-title class="pa-0">
            <v-icon icon="mdi-map-marker-outline"></v-icon>
            {{ city }}
          </v-card-title>
          <span class="px-4 subtitle date-span">
            {{ date }}
          </span>
        </div>

        <div v-if="options.length > 0">
          <div
              class="d-flex align-center"
              v-for="(prop, i) in options"
              style="padding: 5px"
              :key="i"
          >
            <img :src="prop.image" style="width: 25px" :alt="prop.name" />

            <div class="px-1 div-value" v-for="(ele, i) in prop.values" :key="i">
              <div v-if="typeof ele === 'object'">
                <p>
                  {{
                    ele[0] === "true"
                        ? "نعم"
                        : ele[0] === "false"
                            ? "لا"
                            : ele[0] === "0"
                                ? ele[0]
                                : ele[0]
                  }}
                </p>
              </div>
              <div v-else>
                {{ ele}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="d-flex align-center justify-start flex-wrap">
        <div
          v-for="(item, i) in numwidth"
          :key="i"
          class="px-2 mb-2"
          style="width: calc(100% / 2)"
        >
          <div v-if="item.show_outside == 1" class="d-flex align-center">
            <img :src="item.image" style="width: 25px" :alt="item.name" />
            <div class="px-1" v-for="(ele, i) in item.values" :key="i">
              <div v-if="typeof ele === 'object'">
                <p>
                  {{ele[0]}}
                  {{
                    ele[0] === "true"
                      ? "نعم"
                      : ele[0] === "false"
                      ? "لا"
                      : ele[0] === "0"
                      ? ele[0]
                      : ele[0]
                  }}
                </p>
              </div>
              <div v-else>
                <p>
                  {{ ele }}
                </p>
              </div>

              <!-- {{ typeof ele === "object" ? ele[0] : ele }} 
               {{ ele[0]=='true'?"نعم":"لا" }} -->
            </div>
          </div>
        </div>
      </div>
      <v-card-actions class="mt-2">
        <div
          class="text-center w-100"
          style="position: absolute; right: 0; bottom: 8px"
        >
          <button class="previewads" elevation="0" @click="single(useritem.id)">
<!--<img :src="useritem?useritem.image:''" alt="" width="25">-->
<!--            {{ useritem?useritem.name:'' }}-->
            {{$t('view_details')}}
            
             <v-icon class="arrow" icon="mdi-chevron-left"> </v-icon>
          </button>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import crudDataService from '@/Services/crudDataService';

export default {
  data(){
    return{
      useritem:''
    }
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    ad_type: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    city: {
      type: String,
    },
    date: {
      type: String,
    },
    type: {
      type: String,
    },

    numwidth: {
      type: Array,
    },
    idcard: {
      type: Number,
    },
    userid:{
      type:Number
    },
    idsupport: {
      type: Number,
    },
    
  },
  methods: {
    // async userprof(){
    //   let user=await crudDataService.get('user-profile',this.userid)
    //   this.useritem=user.data.data
    // },
    singleAd(id) {
      this.$router.push({ name: "SingleAd", params: { id } });
    },
    single(id) {
      this.$router.push({ name: "SinglePartner", params: { id } });
    },
  },
  mounted(){
    // this.userprof()
  }
};
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 300px;
}

.card-image {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 16px;
}
.carddata .subtitle {
   color: #969696;
  font-size: 20px;
}
.line-date-and-city {
  width: 100%;
  display: flex;
}
.v-card .v-card-title {
  width: 68%;
  font-size: 15px;
}
.date-span {
  font-size: 11px !important;
  width: 31%;
}
/*.span-type {*/
/*  background: #fede2c;*/
/*  border-radius: 8px;*/
/*  padding: 4px 16px;*/
/*}*/
.div-value {
  color: #d7bb1e;
  text-shadow: 1px 1px #0000008c;
}
.span-type {
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fede2c;
  padding: 5px 10px;
  border-radius: 5px;
  transition: transform 1s ease-in-out;
  /*animation: moveToCenter 1s forwards;*/
  /*opacity: 0; !* Start hidden *!*/
}

.carddata .previewads {
  width: 90%;
  height: 50px;
  padding: 2px;
  background: #fede2c;
  border: 1px solid #fede2c;
  display: flex;
  align-items: center;
  justify-content: center;
}


.carddata .previewads:hover {
  background: transparent !important;

}
.card-ads{
  cursor: pointer;
}
.card-ads:hover .span-type {
  animation: moveToCenter 0.5s forwards;
  opacity: 1; /* Show on hover */
}

@keyframes moveToCenter {
  from {
    top: 10px;
    left: 10px;
    transform: translate(-50%, -50%);
  }
  to {
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
