<style>
.swal2-popup {
  display: inline-block !important;
  text-align: center;
  width: 40em;
  height: 350px;
  font-size: 13px;
}
div:where(.swal2-container) button:where(.swal2-close):focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgb(255 255 255 / 50%) !important;
}
div#swal2-html-container {
  height: 110px;
}
.text-center.text-message-error {
  font-size: 16px;
  margin-bottom: 20px;
}
.link-go-ad {
  background-color: #000;
  padding: 8px 10px;
  color: #9d7f12;
  text-decoration: none;
  font-size: 15px;
  font-weight: 800;
  border-radius: 10px;
}
.name-user-for-warn {
  color: #a3830c;
  font-size: 15px;
  font-weight: 800;
}
</style>
<template>
  <v-container v-if="!is_true">
    <section class="pb-12">
      <h2 class="text-center mb-2">
        {{ $t("Add_offer") }}
      </h2>

      <label class="font-weight-bold">
        {{ $t("license_number") }}
      </label>
      <v-text-field
        type="number"
        variant="solo"
        class="selectnew"
        v-model="formData.license_number"
      ></v-text-field>


      <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

      <div class="text-center">
        <button class="previewads w-50 pa-2 mt-5" @click="checkNow">
          {{ $t("check_now") }}
        </button>
      </div>

    </section>

  </v-container>


    <v-container v-if="is_true">
      <button class="w-50 pa-2 mt-5 step_previous" @click="is_true=false">
        {{ $t("the previous") }} <span class="mdi mdi-arrow-left"></span>
      </button>
      <section class="pb-12">
        <h2 class="text-center mb-2">
          {{ $t("Add_offer") }}
        </h2>

        <div class="row">
          <h3 class="text-center mb-2 title_data_ads">
            {{ $t("advertiser_details") }}
          </h3>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("advertiserName") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.advertiserName"
            ></v-text-field>

          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("phoneNumber") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.phoneNumber"
            ></v-text-field>

          </div>
          <h3 class="text-center mb-2 title_data_ads">
            {{ $t("Add_details") }}
          </h3>


          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("license_number") }}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="formData2.license_number"
            ></v-text-field>
          </div>
          <div class="col-md-4" v-if="data_ads.landNumber != null">
            <label class="font-weight-bold">
              {{ $t("landNumber") }}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.landNumber"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("adSource") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.adSource"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ data_ads.landTotalPrice == null ? $t("price") :  $t("propertyPrice")}}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.propertyPrice"
            ></v-text-field>
          </div>
          <div class="col-md-4" v-if="data_ads.landTotalPrice">
            <label class="font-weight-bold">
              {{  $t("landTotalPrice")}}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.landTotalPrice"
            ></v-text-field>
          </div>
          <div class="col-md-4" v-if="data_ads.landTotalAnnualRent">
            <label class="font-weight-bold">
              {{  $t("landTotalAnnualRent")}}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.landTotalAnnualRent"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("Aarea") }}
            </label>
            <v-text-field
                type="number"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.propertyArea"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("ads_type") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.advertisementType"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("estate_type") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.propertyType"
            ></v-text-field>

          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("propertyAge") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.propertyAge"
            ></v-text-field>
          </div>
          
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("titleDeedTypeName") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.titleDeedTypeName"
            ></v-text-field>
          </div>
          <div class="col-md-4" style=" align-content: center;text-align: center;">
            <div style="display: flex;">
                <div class="font-weight-bold is_not_have_con" v-if="!data_ads.isPawned">
                  {{ $t("isNotPawned") }}
                </div>
                <div class="font-weight-bold is_have_con" v-if="data_ads.isPawned">
                  {{ $t("isPawned") }}
                </div>
                <div class="font-weight-bold is_not_have_con" v-if="!data_ads.isConstrained">
                  {{ $t("isNotConstrained") }}
                </div>
                <div class="font-weight-bold is_have_con" v-if="data_ads.isConstrained">
                  {{ $t("isConstrained") }}
                </div>
            </div>

          </div>
          <div class="col-md-4" style=" align-content: center;text-align: center;">
            <div style="display: flex;">
                <div class="font-weight-bold is_not_have_con" v-if="!data_ads.isTestment">
                  {{ $t("isNotTestment") }}
                </div>
                <div class="font-weight-bold is_have_con" v-if="data_ads.isTestment">
                  {{ $t("isTestment") }}
                </div>
                <div class="font-weight-bold is_not_have_con" v-if="!data_ads.isHalted">
                  {{ $t("isNotHalted") }}
                </div>
                <div class="font-weight-bold is_have_con" v-if="data_ads.isHalted">
                  {{ $t("isHalted") }}
                </div>
            </div>

          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("Area") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.region"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("City") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.city"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label class="font-weight-bold">
              {{ $t("neighborhood") }}
            </label>
            <v-text-field
                type="text"
                variant="solo"
                class="selectnew"
                disabled="true"
                v-model="data_ads.district"
            ></v-text-field>
          </div>

        </div>



        <label class="font-weight-bold">
          {{ $t("usage_type") }}
        </label>
        <Multiselect
            :options="optionsusagetype"
            :searchable="true"
            :placeholder="$t('usage_type')"
            label="name"
            class="selectnew"
            v-model="formData2.usage_type_id"
            @change="usage($event)"
        />
        <!--           -->

        <label class="font-weight-bold">
          {{ $t("description") }}
        </label>
        <v-text-field
            variant="solo"
            class="selectnew"
            v-model="formData2.description"
        ></v-text-field>
        <!-- <label class="font-weight-bold">
          {{ $t("address") }}
        </label>
        <v-text-field
          variant="solo"
          class="selectnew"
          v-model="formData.address"
        ></v-text-field> -->
        <label class="font-weight-bold">
          {{ $t("Location_Details") }}
        </label>
        <v-text-field
            variant="solo"
            class="selectnew"
            v-model="formData2.location"
        ></v-text-field>
        <label class="font-weight-bold">
          {{ $t("lat") }}
        </label>
        <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            disabled="true"
            v-model="formData2.lat"
        ></v-text-field>
        <label class="font-weight-bold">
          {{ $t("lang") }}
        </label>
        <v-text-field
            type="number"
            variant="solo"
            class="selectnew"
            disabled="true"
            v-model="formData2.lng"
        ></v-text-field>



        <!--

                <label class="font-weight-bold">
                  {{ $t("advertiser_orientation") }}
                </label>
                <Multiselect
                    :options="optionsadvertiserorientation"
                    :searchable="true"
                    :placeholder="$t('advertiser_orientation')"
                    label="name"
                    class="selectnew"
                    v-model="formData2.advertiser_orientation_id"
                />
                <label class="font-weight-bold">
                  {{ $t("advertiser_type") }}
                </label>
                <Multiselect
                    :options="optionadvertisertype"
                    :searchable="true"
                    :placeholder="$t('advertiser_type')"
                    label="name"
                    class="selectnew"
                    v-model="formData2.advertiser_type"
                />

                <label class="font-weight-bold">
                  {{ $t("delegation_number") }}
                </label>
                <v-text-field
                    type="number"
                    variant="solo"
                    class="selectnew"
                    v-model="formData2.delegation_number"
                ></v-text-field>
                 <label class="font-weight-bold">
                  {{ $t("advertiser_registration_number") }}
                </label>
                <v-text-field
                  type="number"
                  variant="solo"
                  class="selectnew"
                  v-model="formData.advertiser_registration_number"
                ></v-text-field> -->
        <v-checkbox
            :label="$t('special')"
            class="check"
            @change="checkspcial"
        ></v-checkbox>
        <v-checkbox label="Story" class="check" @change="checked" v-if="story"></v-checkbox>
        <label class="font-weight-bold">
          {{ $t("image") }}
        </label>
        <v-file-input
            variant="solo"
            class="selectnew my-2"
            v-model="formData2.attachment"
            label="Add Image"
            prepend-icon="mdi-camera"
            accept=".pdf, image/jpeg, image/png"
            @change="handleFileChange"
        ></v-file-input>
        <v-row class="mt-2">
          <v-col cols="12" md="3" v-for="item in optionproperties" :key="item.id">
            <label class="font-weight-bold">
              {{ item.name }}
            </label>
            <input
                type="range"
                class="d-block form-control selectnew"
                :min="item.min_value"
                :max="item.max_value"
                style="border-style: solid"
                v-if="item.type == 'slider'"
                v-model="optionsAdd[item.id]"
            />
            <Multiselect
                v-else-if="item.type == 'multi'"
                :options="item.values_ar"
                :searchable="true"
                class="selectnew"
                v-model="optionsAdd[item.id]"
            />
            <v-checkbox
                v-else-if="item.type == 'switch'"
                class="check"
                v-model="optionsAdd[item.id]"
            ></v-checkbox>

            <v-text-field
                v-else
                :type="item.type"
                variant="solo"
                class="selectnew"
                :min="item.min_value"
                :max="item.max_value"
                v-model="optionsAdd[item.id]"
            ></v-text-field>

            <!-- @change="prepareAndAddData(item.id, $event, item.name)" -->
          </v-col>
        </v-row>
        <div class="d-flex align-center">
          <v-checkbox class="check d-flex flex-0-0" @change="check"></v-checkbox>
          <label @click="dialog = true">
            <h3 style="cursor: pointer">
              {{ $t("Conditions_advertisement") }}
            </h3>
          </label>
        </div>
        <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

        <div class="text-center">
          <button class="previewads w-50 pa-2 mt-5" @click="addnewad">
            {{ $t("Add_new_ad") }}
          </button>
        </div>
        <v-dialog v-model="dialog" width="700">
          <v-card class="text-center py-5">
            <h3
                class="w-max-content mx-auto rounded pa-1 text-h5 font-weight-bold bg-yellow-darken-1"
            >
              {{ $t("Conditions_advertisement") }}
            </h3>
            <p class="pa-5 text-h6">
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
              النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
              من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.إذا
              كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
              الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص
              العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير
              من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.ومن هنا وجب على
              المصمم أن يضع نصوصا مؤقتة على التصميم ليظهر للعميل الشكل كاملاً،دور
              مولد النص العربى أن يوفر على المصمم عناء البحث عن نص بديل لا علاقة
              له بالموضوع الذى يتحدث عنه التصميم فيظهر بشكل لا يليق.هذا النص يمكن
              أن يتم تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير
              منظم، غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.
            </p>
          </v-card>
        </v-dialog>
      </section>
      <v-snackbar
          v-model="showSnackbar"
          color="yellow-darken-2
"
      >
        {{ $t("Added_successfully") }}
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import crudDataService from "@/Services/crudDataService";
import imgwarn from "../../assets/icon/warning.png";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      showSnackbar: false,
      errorMessage: "",
      dialog: false,
      estate_id: 1,
      is_true: false,
      data_ads:{},
      nei_id: "",
      license_number: "",

      formData: {
        license_number: "",
      },


      eventchange: "",

      optionsestate: [],
      optionsadtype: [],
      optionsusagetype: [],
      optionsmaintype: [],
      optionsadvertiserorientation: [],
      optionadvertisertype: [],
      formData2: {
        usage_type_id: "",
        main_type: "sell",
        description: "",
        address: "",
        location: "",
        lng: "",
        lat: "",
        price: "",
        estate_notes: 0,
        advertiser_orientation_id: "",
        advertiser_type: "",
        license_number: "",
        delegation_number: "",
        advertiser_registration_number: "",
        attachment: [],
        is_story: 0,
        special: 0,
        options: [],
        usage_id: "",
        estate_id: "",
      },
      optionsAdd:[],
      optionproperties: [],
      story:true,

    };
  },
  methods: {

    async checkNow() {
      try{
        let response = await crudDataService
            .create(`check-ads-license`, this.formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },

            });
        if(response.data.success){
          this.data_ads = response.data.data;
          this.is_true = true;
          this.formData2.license_number =this.formData.license_number;
          this.formData2.lat =this.data_ads.latitude;
          this.formData2.lng =this.data_ads.longitude;
          this.estate_id =this.data_ads.propertyTypeId;

        }else if( response.status = 201){
          this.$swal.fire({
            title: this.$t("Previously_existing_ad"),
            html: `<div class="text-center text-message-error">`+ response.data.message + `<br>`+ this.$t("add_ad_by")+` : <span class="name-user-for-warn"> ` + response.data.data.user_name +`</span></div>
                  <a class="link-go-ad"  href="/ads/single-ads/`+response.data.data.id+`">`+this.$t("go_ad")+`</a> `,
            imageUrl: imgwarn,
            imageWidth: 200,
            imageAlt: "warning",
            showCloseButton: true,
            focusClose:false,
            showCancelButton: false,
            showConfirmButton:false
          });
            console.log(response.status)
          }else{


          this.errorMessage = response.data.message;

        }



      }catch (error) {
        if (error.response) {
          if(error.response.data.message == 'unauthenticated'){
            this.errorMessage= this.$t("pleaseLoginFirst");
          }else {
            this.errorMessage = error.response.data.message;
          }
        } else if (error.request) {
          this.errorMessage = "No response from the server";
        } else {
          this.errorMessage = error.message;
        }
      }
    },




    async getstories() {
      let response = await crudDataService.getAll("stories");
      if (response.data.data.ads[0]) {
        this.story = response.data.data.ads[0].is_story==1?false:true;
      }
    },
    handleFileChange(event) {
      this.formData2.attachment[0] = event.target.files.item(0);
    },
    checked(e) {
      if (e.target.checked) {
        this.formData2.is_story = 1;
      } else {
        this.formData2.is_story = 0;
      }
    },
    check(e) {
      if (e.target.checked) {
        this.formData.estate_notes = 1;
      } else {
        this.formData2.estate_notes = 0;
      }
    },
    checkspcial(e) {
      if (e.target.checked) {
        this.formData2.special = 1;
      } else {
        this.formData2.special = 0;
      }
    },

    async estate_types() {
      let response = await crudDataService.getAll(`estate-types`);
      this.optionsestate = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async usage_types() {
      let response = await crudDataService.getAll(`usage-types`);
      this.optionsusagetype = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },

    async advertiser_orientation() {
      let response = await crudDataService.getAll(`advertiserOrientation`);
      this.optionsadvertiserorientation = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async advertiser_type() {
      this.optionadvertisertype = [
        { value: "owner", name: this.$t("owner") },
        { value: "delegate", name: this.$t("delegate") },
      ];
    },

    usage(e) {
      this.usage_id = e;
      // console.log(e);
      this.properties();
    },
    async properties() {
      let response = await crudDataService.getAll(
          `properties/${this.estate_id}/${this.usage_id}`
      );
      this.optionproperties = response.data.data;
    },
    prepareAndAddData() {
      this.formData2.options = Object.entries(this.optionsAdd).map(([key, value]) => {
        return {
          property_id: key,
          values: [value], // Assuming each value is what you want to add
        };
      });
    },

    async addnewad() {
      this.prepareAndAddData()
      this.formData2.address=  this.data_ads.region+' '+this.data_ads.city+' '+this.data_ads.district

      let response = await crudDataService
          .create(`store-ad-sell`, this.formData2, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.showSnackbar = true;
            setTimeout(() => {
              this.$router.push("/");
            }, 5000);
          })
          .catch((error) => {
            if (error.response) {
              this.errorMessage = error.response.data.message;
            } else if (error.request) {
              this.errorMessage = "No response from the server";
            } else {
              this.errorMessage = error.message;
            }
          });
    },

  },
  mounted() {
    this.estate_types();
    this.usage_types();
    this.advertiser_orientation();
    this.advertiser_type();
    this.getstories()
  },
};
</script>

<style lang="scss">
.selectnew {
  &.multiselect {
    margin-top: 8px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .multiselect-search {
    border-radius: 10px;
  }
}
.selectnew.v-input--density-default .v-field--variant-outlined,
.selectnew.v-input--density-default .v-field--single-line,
.selectnew.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  border-radius: 10px !important;
  margin-top: 6px;
  margin-bottom: 8px;
}
.check .v-input__details,
.selectnew .v-input__details {
  display: none !important;
}
.selectnew.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}
.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
}

.is_have_con{
  margin: 10px;
  padding: 10px;
  color: #fd0000;
  width: 40% !important;
  border-radius: 10px;
  background-color: #e7575761;
}
.is_not_have_con{
  margin: 10px;
  padding: 10px;
  color: #ffffff;
  width: 40% !important;
  border-radius: 10px;
  background-color: #00d37b;
}
.step_previous{
  position: absolute;
  float: left;
  left: 0;
  color: #c30000;
  font-weight: 600;
  font-size: 18px;
}
@media (min-width: 768px){
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }


  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
</style>
