
<style>
/*.nav-header{*/
/*  padding: 0 !important;*/
/*}*/
</style>
<template>
  <v-app>
    <v-main>
      <section class="bg-black">
        <v-container  class="nav-header" v-if="showNavFooter">
          <Navbar />
        </v-container>
      </section>


    <router-view />
    <Footer v-if="showNavFooter"/>
    <WhatsApp/>
    </v-main>
  </v-app>
</template>

<script>

import WhatsApp from './components/WhatsApp/WhatsApp.vue';
import Navbar from './components/Navbar/Navbar.vue';
import Navitem from './components/Navitem/Navitem.vue';
import Footer from './components/Footer/Footer.vue';
import Navmobile from "@/components/Navitem/Navmobile.vue";
export default {
  name: "App",
  data: () => ({}),
  components: {
    Navbar,
    Navmobile,
    Footer,
    WhatsApp
  },
  computed: {
    showNavFooter() {
      // Replace 'NotFound' with the name of your 404 route
      return this.$route.name !== 'NotFound';
    }
  }
};
</script>
