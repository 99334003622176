<template >
    <section>
      <div v-if="user.whatsapp" style="position: fixed;left: 5%;bottom: 30%;z-index: 555;cursor: pointer; ">

          <a :href="`https://api.whatsapp.com/send?phone=+966${user.whatsapp}&text=السلام%20عليكم%20ورحمة%20الله%20وبركاته`" class="text-black iconwhatsapp"          target="_blank">
              <v-img src="../../assets/icon/chat.svg" width="38" class="px-2 iconbtn" ></v-img>
          </a>
          <a :href="`tel:${user.whatsapp}`" class="text-black iconwhatsapp"         target="_blank">
              <v-img src="../../assets/icon/call.svg" width="38" class="px-2 iconbtn" ></v-img>
          </a>
  
        </div>
    </section>
    
</template>
<script>
import crudDataService from '../../Services/crudDataService';
export default {
    data(){
        return{
            user:''
        }
    },
    methods:{
        async userprofile() {
      const id = localStorage.getItem("userId");
      if (id) {
        let response = await crudDataService.get(`user-profile`, id);
        this.user=response.data.data;
      
      }
     
     
    },
    },
    mounted() {
        this.userprofile()
    },
}
</script>
<style>
     .iconwhatsapp{
        background: rgb(0 0 0);
        border-radius: 15px;
        width: 55px;
        height: 55px;
        display: grid;
        text-align: center;
        place-items: center;
        text-decoration: none;
        margin-bottom: 5px;
    }
</style>