<template>
    <div class="d-flex align-center w-75 justify-space-between navitem ">
        <v-list class="d-flex align-center justify-end w-70 bg-black">
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="navigateTo(item.route)"
            >
              <v-list-item-title class="font-weight-bold" style="font-size: 1.1rem;">{{$t(item.text)}}</v-list-item-title>

          </v-list-item>
        </v-list>
      <button class="px-2 searchmap ma-1 bottom-black" @click="gotomap">{{ $t("SearchMap") }}</button>
      <Authriz class="text-start"/>

    </div>


</template>

<script>
import Authriz from './Authriz'
export default {
    components:{
        Authriz
    },
  data() {
    return {
      drawer: false,
      items: [
        { text: "Home", route: "/" },
        { text: "Real_estate", route: "/real-estate" },
        { text: "Support-Service", route: "/support-service" },
        //{ text: "Blog", route: "/blog" },
        { text: "About", route: "/about-us" },
        // { text: "Contact", route: "/contact" },


      ],
    };
  },
  methods: {
    gotomap(){
      this.$router.push({name:'Map'})
    },
    navigateTo(route) {
      this.$router.push(route)
    },
  },
};
</script>


<style lang="scss" scoped>
.searchmap{
  border: 1px solid #F6C712;;
  background-color: #F6C712;
  border-radius: 6px;
  font-weight: 500;
  height: 40px;
}
 .searchmap:hover{
  background-color: transparent;
  color: #F6C712;
}
@media (max-width: 1270px) {
  .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
}
}
@media (max-width: 991.98px) {

.navitem{
display: none !important;
}
}
</style>