<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" sm="7" md="5" class="pa-xs-3 pa-0 py-5">
      <v-card class="pa-xs-5 px-2 card_login" elevation="0">
        <v-card-title class="text-h4 mb-5 mt-5 weight_text"
          >{{ $t("Password_Reset") }}
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="Reset">
            <label class="weight_text mb-2">{{ $t("Password") }} </label>

            <v-text-field
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              density="compact"
              min="8"
              placeholder="**************"
              prepend-inner-icon="mdi-lock-outline"
              variant="solo"
              class="border rounded mt-2 mb-5"
              single-line
              hide-details
              @click:append-inner="visible = !visible"
              v-model="formData.password"
            ></v-text-field>
            <label class="weight_text mb-2">
              {{ $t("ConfirmPassword") }}
            </label>
            <v-text-field
              :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible1 ? 'text' : 'password'"
              density="compact"
              min="8"
              placeholder="**************"
              prepend-inner-icon="mdi-lock-outline"
              variant="solo"
              class="border rounded mt-2 mb-5"
              single-line
              hide-details
              @click:append-inner="visible1 = !visible1"
              v-model="formData.password_confirmation"
            ></v-text-field>
           
            <div class="text-red mb-2">
              {{ errorMessage ? errorMessage : "" }}
            </div>

            <button type="submit" class="previewads d-block w-100">
              {{ $t("Save") }}
            </button>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";

export default {
  data() {
    return {
      formData: {
        password: "",
        password_confirmation: "",
      },
      errorMessage: "",
      visible: false,
      visible1: false,

    };
  },
  methods: {
    async Reset() {
      await crudDataService
        .create(`reset-password`, this.formData)
        .then((res) => {
          // // console.log(res.data.data, "wdjqpowjpwd");
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          // console.log(error.request);
          // console.log(error.response);
          // console.log(error.response.data.message);
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
          console.error("Login error:", this.errorMessage);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.card_login {
  .weight_text {
    font-weight: 900;
    text-align: center;
    font-size: 16px;
  }
  .previewads {
    height: 45px;
    font-weight: 900;
  }
}
</style>
