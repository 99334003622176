<template>
  <v-carousel
    height="650"
    hide-delimiter-background
    :show-arrows="false"
    class="carousel"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      cover
      class="imagecarousel"
    >
      <div class="d-flex fill-height justify-center align-center">
        <div class="text-h2 font-weight-bold text-white descrip">
          {{ $t(item.desciption) }}
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import aqarimg from "../../assets/images/Recaqar.png";
import unsplash from "../../assets/images/unsplash.png";
import images from "../../assets/images/images.jpeg";
export default {
  data() {
    return {
      items: [
        {
          src: aqarimg,
          desciption: "carouseldescription",
        },
        {
          src: unsplash,
          desciption: "carouseldescription",
        },
        {
          src: images,
          desciption: "carouseldescription",
        },
      ],
    };
  },
};
</script>
<style>
.carousel .v-img__img--cover {
  filter: brightness(0.5);
}
.carousel.v-carousel--hide-delimiter-background .v-carousel__controls {
  bottom: 0% !important;
  color: #fff !important;
  font-size: 10px !important;
}
.carousel .v-btn--active > .v-btn__overlay,
.v-btn[aria-haspopup="menu"][aria-expanded="true"] > .v-btn__overlay {
  opacity: 0 !important;
}
.carousel .v-btn--icon {
  color:#ffffff5c ;
}
.v-btn--active {
  color: #e9bd11 !important;
}
.carousel .v-btn--icon .v-icon {
  font-size: 10px;
}

.carousel .v-btn--active  .v-icon{
  font-size: 17px;
}
.carousel .v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 2px);
  height: calc(var(--v-btn-height) + 1px);
}
.carousel .v-ripple__container {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.descrip {
  width: 43%;
  text-align: center;
}
@media (max-width: 991.98px) {
  .descrip {
    width: 65%;
    font-size: 40px !important;
  }
}
@media (max-width: 700px) {
  .descrip {
    width: 90%;
    font-size: 35px !important;
    line-height: 1.3 !important;
  }
}
</style>
<!-- <template>
  <v-carousel height="650" hide-delimiter-background :show-arrows="false" cycle touch class="carousel" v-if="items.length>0">
        <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.image"
      cover
    >
    
    </v-carousel-item>
     
   
  </v-carousel>
  <v-row v-else class="skeleton">
    <v-col cols="12" md="12">
      <v-skeleton-loader 
      class="mb-4" height="580"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
-->
<!-- <script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
        items: [  
        ],
    };
  },
  methods: {
    async slider() {
      let response = await crudDataService.getAll(`sliders`);
      // console.log(response.data.data[0].image);
      this.items = response.data.data;
    
    },
  },
  mounted() {
    this.slider();
  },
};
</script> -->
<!-- <style>
    .carousel.v-carousel--hide-delimiter-background .v-carousel__controls {
    bottom: 25% !important;
    color: #fff !important;
    font-size: 10px !important;

    }
   .carousel .v-btn--active > .v-btn__overlay, .v-btn[aria-haspopup=menu][aria-expanded=true] > .v-btn__overlay {
    opacity: 0 !important;
}
.carousel .v-btn--icon .v-icon {
    font-size: 10px;

}
.carousel .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 2px);
    height: calc(var(--v-btn-height) + 1px);
}
.carousel .v-ripple__container {
    display:none !important;
}
</style>
<style lang="scss" scoped>
.descrip{
    width: 43%;
    text-align: center;
}
@media (max-width: 991.98px) {
    .descrip{
    width: 65%;
    font-size: 40px !important;

}}
@media (max-width: 700px) {
    .descrip{
    width: 90%;
    font-size: 35px !important;
    line-height: 1.3 !important;
}
}
</style> -->
