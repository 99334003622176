<template>
  <section v-if="items" class="w-100">
    <div class="  justify-space-between align-center w-100">
      <p class="text-h4 font-weight-bold header-page">
        <v-img cover  src="../../assets/icon/about-us.svg" ></v-img>

         <span class="title-about"> {{ $t("About") }}</span>
      </p>
      <div class="item-sec w-100">
            <a
                :href="`${items.twitter}`"
                target="_blank"
                class="text-decoration-none text-white mx-2"
              >
              <v-icon>
            mdi-twitter
          </v-icon>
              </a>
              <a
                :href="`${items.instagram}`"
                target="_blank"
                class="text-decoration-none text-white mx-1"
              >
              <v-icon>
            mdi-instagram
          </v-icon>
              </a>
              <a
                :href="`${items.facebook}`"
                target="_blank"
                class="text-decoration-none text-white mx-1"
              >
              <v-icon>
            mdi-facebook
          </v-icon>
              </a>
        
          </div>
    </div>
    <v-container>
         <div v-html="items.about_us" class="text-content text-center"></div>
    </v-container>
  </section>

  <v-row v-else >
      
      <v-col cols="12" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0 "
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
</template>

<script>
import crudDataService from "@/Services/crudDataService";
export default {
  data() {
    return {
      items: "",
      texts: [1, 2, 3, 4,5],
    };
  },

  methods: {
    async getabout() {
      let response = await crudDataService.getAll("application-data");
      // console.log(response.data.data);
      this.items = response.data.data;
    },
  },
  mounted() {
    this.getabout();
  },
};
</script>

<style>
.header-page {
  color: rgba(17, 24, 39, 1);
}
.text-content{
    font-size: 28px;
    line-height: 1.9;
    font-weight: 500;
}
span.title-about {
  position: absolute;
  top: 11%;
  left: 50%;
  color: #fff;
}
.item-sec {
  position: absolute;
  top: 6%;
  right: 90%;
  z-index: 99;
}
</style>
