<template>
  <section>
    <div class="d-flex justify-space-between align-center">
      <Logo />
      <SearchMap class="d-sm-flex d-none justify-center align-center mt-2  " />
      <Items  class="d-sm-flex d-none justify-center align-center mt-2 "  />

      <Changelanguage />
    </div>
    <div class="d-sm-none d-flex justify-center align-center mt-2 m-auto flex-wrap">
      <SearchMap />
    </div>
  </section>
</template>
<script>
import Changelanguage from "./Changelanguage.vue";
import Logo from "./Logo.vue";
import SearchMap from "./SearchMap.vue";
import Items from "@/components/Navitem/Items.vue";
export default {
  components: {
    Changelanguage,
    Logo,
    Items,
    SearchMap,
  },
};
</script>
<style lang="scss" scoped></style>
