<template>
  <div class="d-flex align-center" @click="navigateTo('/')" style="cursor: pointer;">
    <v-img
      src="../../assets/logo.png"
      width="80"
      height="65"
      class="flex-0-0"
    ></v-img>
<!--    <p class="px-2 header">{{ $t("Brand") }}</p>-->
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    navigateTo(route) {
      this.$router.push(route)
    },
  },
};
</script>
<style lang="scss" scoped></style>
