<template>
  <div>
    <Story/>
    <Carousel/>
    <Tabs/>
    <!-- <v-container> -->

    <Partners/>
    <!-- <Real_estate/> -->
    <!-- </v-container> -->
    <v-container>
      <Real_Service_home />
    </v-container>
  </div>

</template>

<script>
import { defineComponent } from 'vue';
import Story from '../components/Stories/Story.vue';
import Carousel from '../components/Carousel/Carousel.vue';
import Fillter from '../components/Fillter/Fillter.vue';
import Tabs from '../components/Tabs/Tabs.vue';
import Most from '../components/Most/Most.vue';
import Partners from '../components/Partners/Partners.vue';
import Real_estate from '../components/Real_estate/Real_estate.vue';
import Real_Service_home from "@/components/Real_Service/Real_Service_home.vue";

export default defineComponent({
  name: 'HomeView',


  components: {
    Story,
    Carousel,
    Fillter,
    Real_Service_home,
    Tabs,
    Most,
    Partners,
    Real_estate
  },
  
});
</script>
