<template>
  <section class="section_most m-0 p-0 ">
    <v-container>
    <div class="text-center mb-5">
<!--      <p class="text-h4 font-weight-bold">-->
<!--        {{ $t("Mostpopular") }}-->
<!--      </p>-->
      <p style="color: #00000087; font-size: 28px" class="font-weight-bold">
        {{ $t("Check_out_thelatest") }}
      </p>
    </div>

    <v-row>
      <v-col cols="12" md="3" v-for="(ad, i) in items" :key="i">
        <Card
          :image="ad.attachment[0]"
          :title="ad.price"
          :subtitle="ad.estate_type+' '+ ad.ad_type"
          :city="ad.city"
          :date="ad.publish_date"
          :type="ad.estate_type"
          :ad_type="ad.ad_type"
          :idcard="ad.id"
          :options="ad.options"
            :userid="ad.user_id"
          
        ></Card>
      </v-col>
    </v-row>
  </v-container>
  </section>

</template>
<script>
import Card from "../Card/Card.vue";
import crudDataService from "@/Services/crudDataService";

export default {
  components: {
    Card,
  },
  data() {
    return {
      items: [],
      options: [],
      listArray:[]
    };
  },
  methods: {
    async mostpopuler() {
      let response = await crudDataService.getAll(`ads`);
      this.listArray = response.data.data.sell.nearest;
     this.items=this.listArray.slice(0, 8)
     // console.log("most popular", this.items);
      this.items.forEach((element) => {
        this.options = element.options;
      });
    },
  },
  mounted() {

    this.mostpopuler();
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 960px){
.section_most .v-container {
    max-width: 1108px;
}}
@media (min-width: 1280px){
.section_most .v-container {
    max-width: 1470px;
}

}
</style>
