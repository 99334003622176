<template>
  <section v-if="items">
    <div class="text-center my-6" style="    display: flex;    justify-content: center;">
      <p class="text-h4 font-weight-bold header-page" style="    width: 175px;    margin-top: 25px;">

        {{ $t("privacy") }}

      </p>

      <v-img
          src="../../assets/icon/privacy-policy.png"
          class="flagicon flex-0-0"
          width="150px"
          alt=" {{ $t('privacy') }}"
      />
    </div>

    <div v-html="items" class="text-content"></div>
  </section>
  <v-row v-else>
    <v-col cols="12" v-for="text in texts" :key="text">
      <v-skeleton-loader
        class="mx-auto border-0"
        type="paragraph"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import crudDataService from "../../Services/crudDataService.js";

export default {
  data() {
    return {
      items: "",
      texts: [1, 2, 3, 4,5],

    };
  },

  methods: {
    async privacy() {
      let response = await crudDataService.getAll("application-data");
      this.items = response.data.data.privacy;

    },
  },
  mounted() {
    this.privacy();
  },
};
</script>
