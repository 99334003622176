<template>
  <v-container v-if="items.length > 0" class="pa-0">
    <div class="text-start my-6">
      <p class="text-h5 font-weight-bold">
        {{ $t("Ad") }}
      </p>
    </div>

    <v-row>
      <v-col cols="12" md="3" v-for="(ad, i) in items" :key="i" class="pr-0">
        <Card
            :image="ad.attachment[0]?ad.attachment[0]:''"
            :title="ad.price"
            :ad_type="ad.ad_type"
            :subtitle="ad.estate_type?ad.estate_type:ad.title + ' ' + ad.ad_type?ad.ad_type:''"
            :city="ad.location? ad.location :ad.city"
            :date="ad.publish_date?ad.publish_date:''"
            :type="ad.estate_type?ad.estate_type:ad.support_service"
            :idcard="ad.id"
            :options="ad.options"
            :userid="ad.id?ad.user_id:''"
        ></Card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Card from "../Card/Card.vue";
import crudDataService from "@/Services/crudDataService";

export default {
  components: {
    Card,
  },
  data() {
    return {
      items: [],
      options: [],
      listArray: [],
    };
  },
  methods: {
    async userads() {
      let response = await crudDataService.get(
        `user`,
        `ads/${this.$route.params.id}`
      );
      this.listArray = response.data.data;
      this.items = this.listArray.slice(0, 12);
      // // console.log(response.data.data);
      // // console.log(this.items);
      this.items.forEach((element) => {
        this.options = element.options;
      });
    },
  },
  mounted() {
    this.userads();
  },
};
</script>
<style>
.date-span {
  font-size: 12px !important;
}
</style>
