// write comment to this ?
<template>
  <v-list  v-if="items.length > 0" class="d-flex align-start story">
    <v-list-item v-for="item in items" :key="item.id">
      <v-dialog width="90%" class="story-overlay mx-auto">
        <template v-slot:activator="{ props }">
          <div class="image" v-bind="props" @click="showstory(item.id)">
            <img
              :src="item.attachment[0]"
              aspect-ratio="1"
              class="rounded-circle"
            />
          </div>
          <p class="font-weight-bold text-center pt-2">
            {{ item.user.name ? item.user.name : $t("Real_company") }}
          </p>
        </template>

        <template v-slot:default="{ isActive }" class="d-flex flex-row">
          <v-icon
            icon="mdi-close"
            @click="isActive.value = false"
            class="text-white ma-3"
          ></v-icon>
          <v-card
            class="mx-auto overflow-hidden bg-transparent elevation-0"
            v-if="storydetail.user"
            width="75%"
          >
            <div class="ma-auto">
              <div class="position-absolute d-flex align-center pa-1">
                <img :src="storydetail.user.image" alt="" class="imageuser" />
                <p
                  class="text-white font-weight-medium px-1"
                  style="font-size: 16px"
                >
                  {{ storydetail.user.name }}
                </p>
              </div>
              <img
                :src="storydetail.attachment[0]"
                aspect-ratio="1"
                class="ma-auto position-relative ads-image"
              />
              <!-- <img :src="story" aspect-ratio="1" class="ma-auto ads-image" /> -->
              <div class="position-absolute" style="bottom: 13%">
                <div class="d-flex align-center">
                  <div class="icon-ads">
                    <img :src="eye" class="w-100" />
                  </div>
                  <p class="text-white px-1">
                    {{ storydetail.story_views }} {{ $t("View") }}
                  </p>
                </div>
                <div class="d-flex align-center">
                  <div class="icon-ads" @click="togglelike(storydetail.id)">
                    <img :src="like" class="w-100" />
                  </div>
                  <p class="text-white px-1">
                    {{ storydetail.story_likes }} {{ $t("Likes") }}
                  </p>
                </div>
              </div>
            </div>
            {{ storydetail.id }}
            <button class="previewads" @click="single(storydetail.id)">
              {{ $t("Ads") }}
            </button>
          </v-card>
          <v-row v-else justify-center>
            <v-col cols="4" class="ma-auto">
              <v-skeleton-loader
                class="mx-auto border-0"
                color="black"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
      </v-dialog>
    </v-list-item>
  </v-list>
</template>

<script>
import story from "../../../src/assets/images/Rectan.png";
import eye from "../../../src/assets/icon/eye.png";
import like from "../../../src/assets/icon/like.png";
import crudDataService from "@/Services/crudDataService";
import { useAuth } from "@/axios";
import { ref } from "vue";
export default {
  data() {
    return {
      story: story,
      eye,
      like,
      items: [],
      storydetail: [],
      likeitem: "",
    };
  },
  methods: {
    single(id) {
      this.$router.push({ name: "SingleAd", params: { id } });
    },
    async getstories() {
      let response = await crudDataService.getAll("stories");
      this.items = response.data.data.ads;
      // // console.log(this.items);
    },
    async showstory(id) {
      // // console.log(id);
      const { isLoggedIn } = useAuth();
      if (ref(isLoggedIn()).value) {
        let respose = await crudDataService.get("stories", id);
        this.storydetail = respose.data.data.ad;
      } else {
        this.$router.push({ name: "LogIn" });
      }

      // // console.log(this.storydetail);
    },
    async togglelike(id) {
      // // console.log(id);
      let respose = await crudDataService.get(
        "stories",
        `${id + "/toggle-like"}`
      );
      // // console.log(this.storydetail);
      this.storydetail = [];
      this.showstory(id);
    },
  },

  mounted() {
    this.getstories();
  },
};
</script>
<style>
.story .v-list-item__content {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 150px;
}
.story-overlay .v-overlay__scrim {
  background: linear-gradient(
    180.83deg,
    #000000 20.03%,
    rgba(82, 34, 34, 0) 125.46%
  ) !important;
  opacity: 1;
}
@media (max-width: 700px) {
  .story-overlay.v-dialog > .v-overlay__content {
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}
</style>
<style scoped>
.imageuser {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.icon-ads {
  margin-right: 10px;
  margin-left: 10px;
  width: 30px !important;
  height: 30px !important;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  overflow: hidden;
  margin-bottom: 3px;
  cursor: pointer;
}
.previewads {
  width: 231px;
  height: 45px;
}
.story .image {
  cursor: pointer;
  background: #000;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(173, 144, 27, 0.937);
}
.story img {
  margin: auto;
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  padding: 1px;
}

.story::-webkit-scrollbar {
  height: 0 !important;
}
.story::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to left,
    rgba(246, 199, 18, 1),
    rgba(59, 48, 4, 1),
    rgba(0, 0, 0, 0.262),
    rgba(255, 202, 0, 0.25)
  );
}
.story-overlay .ads-image {
  width: 100%;
  height: 70vh;
}
@media (max-width: 700px) {
  .story-overlay.v-dialog > .v-overlay__content {
    max-height: 100%;
    max-width: 100%;
    margin: 0;
  }
  .story-overlay .ads-image {
    width: 100%;
    height: 70vh;
  }
  .story-overlay.v-dialog > .v-overlay__content > .v-card,
  .v-dialog > .v-overlay__content > form > .v-card {
    width: 100% !important;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (max-width: 500px) {
  .icon-ads {
    width: 28px !important;
    height: 28px !important;
    padding: 4px;
  }
}
</style>
